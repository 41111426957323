import { selector } from "recoil";
import { Api } from "../utils";

export const LogsSelector = selector({
  key: 'Logs',
  get: async () => {
    try {
      return await Api.logs();
    } catch (err) {
      console.log(err);
    }
    return undefined;
  },
});