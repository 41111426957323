import { Loadable } from "recoil";

export const fixed = (v: number) => {
  return commas(Math.floor(v).toString());
}

export const commas = (v: number | string) => {
  const parts = v.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join('.');
}

export function memoize<R, T extends (...args: any[]) => R>(f: T): T {
  const memory = new Map<string, R>();

  const g = (...args: any[]) => {
    if (!memory.get(args.join())) {
      memory.set(args.join(), f(...args));
    }

    return memory.get(args.join());
  };

  return g as T;
}

export function loadable<T>(l: Loadable<T>): undefined | T {
  if (l.state === 'loading') {
    return undefined;
  }
  return l.valueOrThrow();
}

export function shorten_middle(text: string, left: number, right: number): string {
  if (text.length <= left + right + 2) {
    return text;
  }
  return text.substring(0, left) + '..' + text.substring(text.length - right - 1);
}

export * as Api from "./api";
