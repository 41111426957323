import "./Rounds.scss";
import { useNavigate } from "react-router-dom"
import { useRecoilValueLoadable } from "recoil";
import { Container, Table } from "semantic-ui-react";
import { RoundsSelector } from "../models/Rounds";

export default function Rounds() {
  const rounds = useRecoilValueLoadable(RoundsSelector).valueMaybe();
  const nav = useNavigate();
  const click = (id: number) => {
    nav('/rounds/' + id);
  };

  return (
    <Container>
      <h2>Rounds</h2>
      <Table id='rounds' selectable unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Round</Table.HeaderCell>
            <Table.HeaderCell>Month</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {rounds === undefined ? '' : rounds.map((round) => (
            <Table.Row onClick={() => { click(round.id) }}>
                <Table.Cell>{round.id}</Table.Cell>
                <Table.Cell>{round.month_text}</Table.Cell>
                <Table.Cell>{round.status_text}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Container>
  );
}