import "./Round.scss";
import { Link, useParams } from "react-router-dom";
import { useRecoilValueLoadable } from "recoil";
import { Container, Grid, Icon, Label, Placeholder, Popup, SemanticCOLORS, Table } from "semantic-ui-react";
import { RoundSelector } from "../models/Rounds";
import { fixed } from "../utils";
import Address from "../components/Address";
import { useState } from "react";

export default function Round() {
  let { id } = useParams();
  id = id ? id : '0';
  const round = useRecoilValueLoadable(RoundSelector(id)).valueMaybe();
  const [showAll, setShowAll] = useState(round ? round.status !== 2 : true);

  const status = ['Did not apply', 'In moratorium', 'Paused', 'Active', 'Completed', 'Disqualified'];
  const color = ['yellow', 'olive', 'green'];

  return (
      <Container>
        <h2>Round {id}{round ? `: ${round.month_text}` : ''}</h2>
        <Grid>
          <Grid.Row>
          {round ? <Grid.Column>
            <Label color={round ? color[round.status] as SemanticCOLORS : undefined}>Status<Label.Detail>{round ? round.status_text : ''}</Label.Detail></Label>
            {!round || round.ftm_price === null ? '' : <Label>FTMUSD<Label.Detail>${(round.ftm_price/100).toFixed(2)}</Label.Detail></Label>}
            <Label>Multiplier<Label.Detail>{round ? round.multiplier: <Placeholder><Placeholder.Line /></Placeholder>}</Label.Detail></Label>
          </Grid.Column> : ''}
          </Grid.Row>
          <Grid.Row>
          <Grid.Column>
            <Table collapsing>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Tier</Table.HeaderCell>
                  <Table.HeaderCell>Total reward (FTM)</Table.HeaderCell>
                  <Table.HeaderCell>Monthly reward (FTM)</Table.HeaderCell>
                  <Table.HeaderCell>Minimum TVL (USD)</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {round ? round.rewards.map((reward) => (<Table.Row>
                  <Table.Cell>{reward.tier}</Table.Cell>
                  <Table.Cell textAlign="right">{fixed(reward.reward)}</Table.Cell>
                  <Table.Cell textAlign="right">{fixed(reward.monthly_reward)}</Table.Cell>
                  <Table.Cell textAlign="right">{reward.min_tvl ? fixed(reward.min_tvl/100) : ''}</Table.Cell>
                </Table.Row>)) : Array.from(Array(4).keys()).map((i) => <Table.Row>
                  <Table.Cell>{i + 1}</Table.Cell>
                  <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
                  <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
                  <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
                </Table.Row>)}
              </Table.Body>
            </Table>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
          <Grid.Column>
            Show: <span className={showAll ? '' : 'selection'} onClick={() => setShowAll(true)}>all</span> | <span className={showAll ? 'selection' : ''} onClick={() => setShowAll(false)}>only active</span>
            <Table compact celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Protocol</Table.HeaderCell>
                <Table.HeaderCell>30-day avg TVL (USD)</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Monthly reward (FTM)</Table.HeaderCell>
                <Table.HeaderCell>Address</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {round ? round.participants.filter((p) => showAll || (p.tier !== 0 && p.status === 3)).map((p) => (<Table.Row>
                <Table.Cell><Link to={"/protocols/" + p.slug}>{p.name}</Link></Table.Cell>
                <Table.Cell textAlign="right">{p.tvl ? <span>{p.entries < 25 ? <Popup content={'Only ' + p.entries + ' data points'} on='hover' inverted trigger={<Icon name="warning sign" />} /> : ''} {fixed(p.tvl/100)}</span> : ''}</Table.Cell>
                <Table.Cell>{p.tier === 0 ? 'Insufficient TVL' : status[p.status]}{p.status === 3 && p.sequence ? ` (${p.sequence ? p.sequence : '?'}/12)` : ''}</Table.Cell>
                <Table.Cell textAlign="right">{p.tier > 0  && p.status === 3 ? fixed(p.monthly_reward) : ''}</Table.Cell>
                <Table.Cell className="address">{p.status === 3 && p.address ? <Address address={p.address} /> : ''}</Table.Cell>
              </Table.Row>)) : Array.from(Array(5).keys()).map((i) => <Table.Row>
                    <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
                    <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
                    <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
                    <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
                    <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
                  </Table.Row>)}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Sum</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">{round ? fixed(round.participants.map((p) => p.tier > 0 && p.status === 3 ? p.monthly_reward : 0).reduce((a, b) => a + b, 0)): ''}</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
          </Grid.Column>
        </Grid.Row>
        </Grid>
      </Container>
    );
}