import React from "react";
import { Grid, Icon, Message } from "semantic-ui-react";

export default class ErrorBoundary extends React.Component {
  state: {hasError: boolean};

  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    console.log(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Grid centered id='api_error'>
            <Message negative icon compact>
              <Icon name='warning sign' />
              <Message.Content>
                <Message.Header>Error</Message.Header>
                Unable to load dashboard. Please try again later.
              </Message.Content>
            </Message>
        </Grid>
      );
    }

    return this.props.children; 
  }
}
