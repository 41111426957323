import { selector } from "recoil";
import { memoize } from "../utils";
import { round, rounds } from "../utils/api";

export const RoundsSelector = selector({
  key: 'Rounds',
  get: async () => {
    try {
      return await rounds();
    } catch (err) {
      console.log(err);
    }
    return undefined;
  },
});

export const RoundSelector = memoize((id: string) => selector({
  key: 'Round ' + id,
  get: async () => {
    try {
      return await round(id);
    } catch (err) {
      console.log(err);
    }
    return undefined;
  }
}));