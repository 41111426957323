import { Link } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import { Feed, Icon, SemanticICONS } from "semantic-ui-react";
import { Log, LogExtra } from "../interfaces/Log";
import { shorten_middle } from "../utils";

interface LogsProps {
  logs: Log[],
  round_id?: number,
  protocol_id?: number,
  first?: any
}

const icons: {[key: string]: SemanticICONS} = {
  comment: 'comment',
  round_finalizing: 'hourglass half',
  round_finalized: 'check',
  modify_address: 'pencil',
  disqualify: 'remove circle'
};

function summary(l: Log, round_id?: number, protocol_id?: number) {
  switch (l.action) {
    case 'comment':
      return (
        <>
          <Feed.User>{l.username ? l.username : '?'}</Feed.User> commented
          {l.protocol_name && l.slug && !protocol_id ? <>{' '} on <Link to={'protocols/' + l.slug}>{l.protocol_name}</Link></> : null}
          {l.round_id && !round_id ? <>{' '} in <Link to={'rounds/' + l.round_id}>round {l.round_id}</Link></> : null}
        </>
      )
    case 'round_finalizing':
      return (
        <>
          {l.username ? (<><Feed.User>{l.username}</Feed.User> started {' '}</>) : 'Started '}
          {l.round_id && !round_id ? <><Link to={'rounds/' + l.round_id}>round {l.round_id}</Link></> : null}
          {' '} finalization
        </>
      )
    case 'round_finalized':
      return (
        <>
          {l.round_id && !round_id ? <><Link to={'rounds/' + l.round_id}>Round {l.round_id}</Link></> : 'Round'}
          {' '} finalized
        </>
      )
    case 'modify_address':
      return (
        <>
          <Feed.User>{l.username ? l.username : '?'}</Feed.User> modified payout address
          {l.protocol_name && l.slug && !protocol_id ? <>{' '} of <Link to={'protocols/' + l.slug}>{l.protocol_name}</Link></> : null}
          {l.round_id && !round_id ? <>{' '} in <Link to={'rounds/' + l.round_id}>round {l.round_id}</Link></> : null}
        </>
      )
    case 'disqualify':
      return (
        <>
          <Feed.User>{l.username ? l.username : '?'}</Feed.User> disqualified
          {l.protocol_name && l.slug && !protocol_id ? <>{' '} <Link to={'protocols/' + l.slug}>{l.protocol_name}</Link></> : null}
          {l.round_id && !round_id ? <>{' '} from <Link to={'rounds/' + l.round_id}>round {l.round_id}</Link> onwards</> : null}
        </>
      )
    default:
      return null;
  }
}

function extra(action: string, e: LogExtra) {
  switch (action) {
    case 'comment':
      return (e.message ? e.message : null);
    case 'modify_address':
      return (e.old && e.new ? <>{shorten_middle(e.old, 6, 4)} &#8594; {shorten_middle(e.new, 6, 4)}</> : null)
    default:
      return null;
  }
}

export default function Logs(props: LogsProps) {
  return (
    <Feed size='small'>
      {props.first ? props.first : null}
      {props.logs.map((l) => {
        return {log: l, summary: summary(l, props.round_id, props.protocol_id), extra: l.extra ? extra(l.action, l.extra) : null};
      }).filter(({summary: s}) => s !== null).map(({ log: l, summary: s, extra: e }) => (
        <Feed.Event key={l.log_id}>
          <Feed.Label>{icons[l.action] ? <Icon name={icons[l.action]} /> : null}</Feed.Label>
          <Feed.Content>
            <Feed.Summary>
              {s!}
              <Feed.Date><ReactTimeAgo date={new Date(l.created)} timeStyle='twitter' /></Feed.Date>
            </Feed.Summary>
            {e}
          </Feed.Content>
        </Feed.Event>
      ))}
    </Feed>
  );
}
