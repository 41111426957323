import "./Home.scss";
import { Card, Container, Grid , Statistic}  from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { useRecoilValueLoadable } from "recoil";
import { LogsSelector } from "../models/Logs";
import { RoundsSelector } from "../models/Rounds";
import Logs from "../components/Logs";

// const status = ["Did not apply", "In moratorium", "Paused", "Active"];

export default function Home() {
  const rounds = useRecoilValueLoadable(RoundsSelector).valueMaybe();
  const logs = useRecoilValueLoadable(LogsSelector).valueMaybe();
  const nav = useNavigate();

  let left = 0;
  if (rounds && rounds.length > 0) {
    const now = new Date();
    const m = rounds[0].month;
    if (now.getUTCMonth() === m % 12) {
      const days = (new Date(Math.floor(m/12), m % 12 + 1, 0)).getDate();
      left = days - now.getUTCDate() + 1;
    }
  }
  return (
    <Grid>
      <Grid.Row centered>
        <Card.Group centered>
          <Card color='olive'>
            <Card.Content>
              <Card.Header>Pending applications</Card.Header>
              <Card.Description textAlign='center'>
                <Statistic>
                  <Statistic.Value>0</Statistic.Value>
                  <Statistic.Label>applications</Statistic.Label>
                </Statistic>
              </Card.Description>
            </Card.Content>
          </Card>
          <Card color='orange' className='card_round' onClick={() => {
            if (rounds && rounds.length > 0) {
              nav('rounds/' + rounds[0].id);
            }
          }}>
            <Card.Content>
              <Card.Header>{rounds && rounds.length > 0 ? `Round ${rounds[0].id}: ${rounds[0].month_text}` : 'Round'}</Card.Header>
              <Card.Description textAlign='center'>
                <Statistic>
                  <Statistic.Value>{rounds && rounds.length > 0 ? left : '?'}</Statistic.Value>
                  <Statistic.Label>days left</Statistic.Label>
                </Statistic>
              </Card.Description>
            </Card.Content>
          </Card>
        </Card.Group>
      </Grid.Row>
      <Grid.Row>
        <h2>Action log</h2>
        <Container>
          {logs ? <Logs logs={logs} /> : null}
        </Container>
      </Grid.Row>
    </Grid>
  );
}

/*function Home() {
  return (
    <Container>
      <h1>Fantom incentive program: January 2022</h1>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Label>1 FTM = {(tvls.ftm_usd/100).toFixed(2)} USD</Label>
            <Label>Multiplier: {tvls.multiplier}</Label>
            <Table collapsing>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Tier</Table.HeaderCell>
                  <Table.HeaderCell>Total reward (FTM)</Table.HeaderCell>
                  <Table.HeaderCell>Monthly reward (FTM)</Table.HeaderCell>
                  <Table.HeaderCell>Minimum TVL (USD)</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {tvls.tiers.map((reward, i) => (<Table.Row>
                  <Table.Cell>{i}</Table.Cell>
                  <Table.Cell textAlign="right">{fixed(reward)}</Table.Cell>
                  <Table.Cell textAlign="right">{fixed(reward/12)}</Table.Cell>
                  <Table.Cell textAlign="right">{fixed(reward*tvls.ftm_usd*tvls.multiplier/100)}</Table.Cell>
                </Table.Row>)).filter((_, i) => i !== 0)}
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Table compact celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Protocol</Table.HeaderCell>
                <Table.HeaderCell>30-day avg TVL (USD)</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Monthly reward (FTM)</Table.HeaderCell>
                <Table.HeaderCell>Address</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {tvls.protocols.map((p) => (<Table.Row>
                <Table.Cell><a href={"#" + p.slug}>{p.name}</a></Table.Cell>
                <Table.Cell textAlign="right">{p.tvls.length < 25 ? <Icon name="warning sign" /> : ""}{fixed(p.tvls[p.tvls.length-1].sma/100)}</Table.Cell>
                <Table.Cell>{p.tier > 0 || p.status !== 0 ? status[p.status] : ""}</Table.Cell>
                <Table.Cell textAlign="right">{p.tier > 0  && p.status === 3 ? fixed(tvls.tiers[p.tier]/12) : ""}</Table.Cell>
                <Table.Cell className="address">{p.address ? <a href={"https://ftmscan.com/address/" + p.address} target="_blank" rel="noopener noreferrer">{p.address}</a> : ""}</Table.Cell>
              </Table.Row>))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Sum</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">{fixed(tvls.protocols.map((p) => p.tier > 0 && p.status === 3 ? tvls.tiers[p.tier]/12 : 0).reduce((a, b) => a + b))}</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
          </Grid.Column>
        </Grid.Row>
      {tvls.protocols.map((p) => (<Grid.Row>
          <Grid.Column>
            <a href={"https://defillama.com/protocol/" + p.slug + "/Fantom/USD"} target="_blank" rel="noopener noreferrer"><h2 id={p.slug}>{p.name}</h2></a>
            <Table compact collapsing>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Date</Table.HeaderCell>
                  <Table.HeaderCell>TVL (USD)</Table.HeaderCell>
                  <Table.HeaderCell>30-day avg TVL (USD)</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {p.tvls.map((t) => (<Table.Row>
                  <Table.Cell>{t.date}</Table.Cell>
                  <Table.Cell textAlign="right">{fixed(t.tvl/100)}</Table.Cell>
                  <Table.Cell textAlign="right">{fixed(t.sma/100)}</Table.Cell>
                </Table.Row>))}
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>))}
      </Grid>
    </Container>
  );
}

export default Home;*/