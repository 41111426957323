import { useState } from "react";
import { Button, Input, Popup } from "semantic-ui-react";

export default function Address(props: {address: string}) {
  const address = props.address;
  const [isClosing, setClosing] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const copyAddress = () => {
    navigator.clipboard.writeText(address);
    setOpen(true);
    setTimeout(() => {
      setClosing(true);
    }, 1500);
    setTimeout(() => {
      setOpen(false);
      setClosing(false);
    }, 2000);
  };

  return (
    <Input size='mini' action>
      <input defaultValue={address} />
      <Popup open={isOpen} content='Copied to clipboard' on='click' inverted trigger={<Button icon='copy' size='mini' />} onOpen={copyAddress} className = {`transition fade ${isClosing ? 'out' : 'in'}` } />
      <Button icon='search' size='mini' onClick={() => window.open('https://ftmscan.com/address/' + address, '_blank')}></Button>
    </Input>
  );
}