import { Routes, Route, Link } from "react-router-dom"
import { useRecoilValueLoadable } from "recoil";
import { Image, Menu }  from "semantic-ui-react";
import { Profile } from "../models/Profile";
import Home from "./Home";
import Protocol from "./Protocol";
import Round from "./Round";
import Rounds from "./Rounds";

export default function Dashboard() {
  const profile = useRecoilValueLoadable(Profile).valueMaybe();
  return (
    <div id='dashboard'>
        <Menu fluid icon fixed='top'>
          <Menu.Item className='logo' as={Link} to='/'><Image src='/images/fantom.svg' verticalAlign='middle' /><span>Incentive Program</span></Menu.Item>
          <Menu.Item as={Link} to='/rounds'>Rounds</Menu.Item>
          <Menu.Item>Protocols</Menu.Item>
          <Menu.Item>Applications</Menu.Item>
          {profile ? <Menu.Item position='right'>{profile.username}</Menu.Item> : null}
        </Menu>
      <div id='content'>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/rounds' element={<Rounds />} />
          <Route path="/rounds/:id" element={<Round />} />
          <Route path="/protocols/:slug" element={<Protocol />} />
        </Routes>
      </div>
    </div>
  );
}