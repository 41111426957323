import "./Login.scss";
import { Button, Form, Grid, Header, Message, Segment } from "semantic-ui-react";
import { SetStateAction, useEffect, useState } from "react";
import { login } from "../utils/api";
import { useRecoilRefresher_UNSTABLE } from "recoil";
import { Profile } from "../models/Profile";

function setField(f: (value: SetStateAction<string>) => void): (e: {target: {value: string }}) => void {
  return (e) => f(e.target.value);
}

export default function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isDisabled, setDisabled] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const refresh = useRecoilRefresher_UNSTABLE(Profile);

  const submitLogin = async () => {
    setDisabled(true);
    setLoading(true);
    try {
      if (await login(username, password)) {
        refresh();
        return;
      }
      else {
        setError(true);
      }
    }
    catch (err) {
      console.log(err);
    }
    setDisabled(false);
  };

  useEffect(() => {
    setDisabled(username.length === 0 || password.length === 0);
  }, [username, password]);

  useEffect(() => {
    if (!isDisabled) {
      setLoading(false);
    }
  }, [isDisabled]);

  useEffect(() => {
    if (isLoading) {
      setDisabled(true);
    }
  }, [isLoading]);

  return (
    <Grid centered>
      <Grid.Row>
        <Form className='login' error={isError}>
          <Header>Login to the dashboard</Header>
          <Segment secondary>
            <Form.Input icon='user' iconPosition='left' placeholder='Username' value={username} autoFocus onInput={setField(setUsername)}/>
            <Form.Input icon='lock' iconPosition='left' placeholder='Password' type='password' value={password} onInput={setField(setPassword)} />
            <Message error header='Error' content='Invalid username or password' />
            <Button content='Login' fluid primary onClick={submitLogin} disabled={isDisabled} loading={isLoading} />
          </Segment>
        </Form>
      </Grid.Row>
    </Grid>
  );
}