import { BrowserRouter } from "react-router-dom"
import Login from "./views/Login";
import Dashboard from "./views/Dashboard";
import 'semantic-ui-css/semantic.min.css'
import "./App.scss";
import {Profile} from "./models/Profile";
import { useRecoilValueLoadable } from "recoil";
import { Loader } from "semantic-ui-react";
import { loadable } from "./utils";

function App() {
  const profile = loadable(useRecoilValueLoadable(Profile));
  return (
    <BrowserRouter>
      <div className='main'>
        {profile === undefined ? <Loader active /> : (profile == null ? <Login /> : <Dashboard />)}
      </div>
    </BrowserRouter>
  );
}

export default App;
