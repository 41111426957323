import { Log } from '../interfaces/Log';
import { Profile } from '../interfaces/Profile';
import { Protocol } from '../interfaces/Protocols';
import { Round, RoundSummary } from '../interfaces/Rounds';

const api_url: string = process.env.REACT_APP_API_URL!;

const request = async (url: string, method?: string, body?: any, rej = true): Promise<any> => {
  const init = {
    method: method ? method : 'GET',
    mode: 'cors' as RequestMode,
    credentials: 'include' as RequestCredentials,
    headers: {'Content-Type': 'application/json'} as HeadersInit,
    body: body ? JSON.stringify(body) : undefined,
  };
  const res = await fetch(api_url + url, init);
  if (rej) {
    if (!res.ok) {
      return reject(res);
    }
    return await res.json();
  }
  return res;
}

const reject = (res: Response): Promise<never> => {
  return Promise.reject(Error('HTTP ' + res.status + ': ' + res.statusText));
}

export const profile = async (): Promise<null | Profile> => {
  return await request('profile');
}

export const login = async (username: string, password: string): Promise<boolean> =>  {
  const res = await request('login', 'POST', {username: username, password: password}, false);
  if (res.status === 200) {
    return true;
  }
  if (res.status === 400) {
    return false;
  }
  return reject(res);
}

export const rounds = async (): Promise<RoundSummary[]> => {
  return await request('rounds');
}

export const round = async (round: string): Promise<Round> => {
  return await request('rounds/' + round);
}

export const protocol = async (protocol: string): Promise<Protocol> => {
  return await request('protocols/' + protocol);
}

export const logs = async (): Promise<Log[]> => {
  return await request('logs');
}

export const comment = async (slug: string, message: string): Promise<void> =>  {
  await request('protocols/' + slug + '/comment', 'POST', {message: message});
}
