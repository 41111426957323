import "./Protocol.scss";
import { useParams } from "react-router-dom";
import { useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from "recoil";
import ReactTimeAgo from 'react-time-ago'
import { Button, Container, Feed, Form, Grid, Icon, Message, Table, TableBody } from "semantic-ui-react";
import { ProtocolSelector } from "../models/Protocol";
import Address from "../components/Address";
import { fixed, loadable } from "../utils";
import { Profile } from "../models/Profile";
import { useEffect, useState } from "react";
import { Api } from "../utils";
import { LogsSelector } from "../models/Logs";
import Logs from "../components/Logs";

export default function Protocol() {
  let { slug } = useParams();
  if (!slug) {
    slug = '';
  }
  const protocol = loadable(useRecoilValueLoadable(ProtocolSelector(slug)));
  const profile = useRecoilValueLoadable(Profile).valueMaybe()!;
  const [showForm, setShowForm] = useState(false);
  const [message, setMessage] = useState('');
  const [isDisabled, setDisabled] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const refresh = [
    useRecoilRefresher_UNSTABLE(ProtocolSelector(slug)),
    useRecoilRefresher_UNSTABLE(LogsSelector)
  ];

  const click = () => {
    setShowForm(!showForm);
  };
  const submitComment = async () => {
    setDisabled(true);
    setLoading(true);
    try {
      await Api.comment(slug!, message);
      setShowForm(false);
      setMessage('');
      refresh.forEach((r) => r());
    }
    catch (err) {
      setError(true);
      console.log(err);
    }
    setDisabled(false);
  }

  useEffect(() => {
    setDisabled(message.length === 0);
  }, [message]);

  useEffect(() => {
    if (!isDisabled) {
      setLoading(false);
    }
  }, [isDisabled]);

  useEffect(() => {
    if (isLoading) {
      setDisabled(true);
    }
  }, [isLoading]);

  const status = ['Did not apply', 'In moratorium', 'Paused', 'Active', 'Completed', 'Disqualified'];

  return (
      <Container>
        <h2>{protocol?.name}</h2>
        <Grid >
          <Grid.Row>
            <Grid.Column className="protocol_details">
              <Table collapsing>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Status</Table.Cell>
                    <Table.Cell>{protocol ? status[protocol.status] : ''}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Address</Table.Cell>
                    <Table.Cell>{protocol && protocol.address ? <Address address={protocol.address} /> : ''}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>DefiLlama chain code</Table.Cell>
                    <Table.Cell>{protocol?.chain_code}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>First seen</Table.Cell>
                    <Table.Cell>{protocol?.start}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Last seen</Table.Cell>
                    <Table.Cell>{protocol?.latest}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <h3>Action log <Button icon size='tiny' onClick={click}><Icon name='plus' /></Button></h3>
              <Container>
                <Feed size='small'>
                  {protocol ? <Logs logs={protocol.logs} protocol_id={protocol.id} first={showForm ? (
                    <Feed.Event key='add'>
                      <Feed.Label><Icon name='comment' /></Feed.Label>
                      <Feed.Content>
                        <Feed.Summary>
                          <Feed.User>{profile.username}</Feed.User> commented
                          <Feed.Date>now</Feed.Date>
                        </Feed.Summary>
                        <Feed.Extra text>
                          <Form error={isError}>
                            <Form.TextArea onInput={(e: any) => setMessage(e.target.value)} value={message} autoFocus />
                            <Message error header='Error' content='Unable to submit comment' onDismiss={() => setError(false)} />
                            <Button size='tiny' onClick={submitComment} content='Comment' disabled={isDisabled} loading={isLoading}></Button>
                          </Form>
                        </Feed.Extra>
                      </Feed.Content>
                    </Feed.Event>
                  ) : null} /> : null}
                  </Feed>
                </Container>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <h3>TVL</h3>
              <Table compact collapsing>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Date</Table.HeaderCell>
                    <Table.HeaderCell>TVL (USD)</Table.HeaderCell>
                    <Table.HeaderCell>30-day avg TVL (USD)</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <TableBody>
                  {protocol ? protocol.tvls.map((t) => (
                    <Table.Row key={t.date}>
                      <Table.Cell>{t.date}</Table.Cell>
                      <Table.Cell textAlign="right">{t.tvl ? fixed(t.tvl/100) : '?'}</Table.Cell>
                      <Table.Cell textAlign="right">{t.sma ? fixed(t.sma/100) : '?'}</Table.Cell>
                    </Table.Row>
                  )) : null}
                </TableBody>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
}