import React from "react";
import ReactDOM from "react-dom";
import { RecoilRoot } from "recoil";
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'

import App from "./App";
import ErrorBoundary from "./components/ErrorBoundary";

TimeAgo.addDefaultLocale(en)

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById("root")
);
